import React, { Component } from "react";
import styled from "styled-components";
import fetch from "isomorphic-fetch";

import Helmet from "react-helmet";

import { Link } from "gatsby";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import { Row, Col } from "react-grid-system";

import TitleContainer from "components/Elements/TitleContainer";
import TitleHeader from "components/Elements/TitleHeader";

const Container = styled.div`
  font-size: 20px;
  margin: auto;
  max-width: 95%;
`;
const Message = styled.p`
  line-height: 1.5;
  font-size: 25px;
`;

const SubQuote = styled.h1`
  color: #333;
  font-size: 30px;
  padding: 0;
  margin: 0;
`;

const Testimony = styled.h3`
  color: #333;
  font-size: 36px;
  background: #f4f4f4;
  padding: 0;
  text-align: center;
  padding: 5%;
  margin: 0;


  .author {
    font-size: 25px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  text-align: center;
  font-size: 1.4em;
  color: #191919;
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
  }
`;

const Card = styled.div`
  height: 350px;
  margin: 20px;
  background: #fcfcfc;
  -moz-box-shadow: 0 0 5px #e4e4e4;
  -webkit-box-shadow: 0 0 5px #e4e4e4;
  box-shadow: 0 0 5px #e4e4e4;
  transition: all 100ms ease;

  &:hover {
    -moz-box-shadow: 0 0 30px #e4e4e4;
    -webkit-box-shadow: 0 0 30px #e4e4e4;
    box-shadow: 0 0 30px #e4e4e4;
  }
`;

const Img = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 210px;
`;

const Center = styled.div`
  text-align: center;
  img,
  span {
    display: inline;
  }
`;

class ContributePage extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {

  }



  render() {
    return (
      <div>
        <Helmet title="Contribute | Enlight" />
        <TitleContainer>
        <br/>
          <TitleHeader className="playfair">Build an API</TitleHeader>
         
          <br />
       </TitleContainer>
      </div>
    );
  }
}

export default ContributePage;
